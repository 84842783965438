.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.demo-tag:hover{
background-color: #ed802e;
color: white;
}
.demo-tag{
  border-radius: 10px;
padding: 10px;
cursor: pointer;
box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
margin-left: 10px;
}
.demo-tag.active{
  background-color: #ed802e;
  color: white; 
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.overflow-y{
  overflow-y: scroll !important;
  max-height: 400px !important;
}
.overflow::-webkit-scrollbar {
  width: 5px;
}

.overflow::-webkit-scrollbar-track {
  background: #ed802e;
  border-radius: 25px;
}

.overflow::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 25px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #000000;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-osperb{
  color: #1a80bb !important;
}
.bg-osperb{
  background-color: #1a80bb !important;
}
.stiky-bar{
  position: sticky;
  top: 0;
  z-index: 9999;
}
.marked{
  background-color: lightyellow;
  border-radius: 4px;
  padding: 0 4px;
}
.redMark{
  background-color:  rgba(240, 128, 128, 0.449);
  border-radius: 4px;
  padding: 0 4px;
}
.code-screen textarea {
caret-color: orange;
}

ul li{
  padding-bottom: 10px;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: transparent !important;
}

/* counter */
:root {
  --body-color: #071324;
  --extra--color: #121b29;
  --main-color: #60f1d0;
  --item-color: #dff6ff;
}

.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.5s ease;
  background: #0a192f;
  border: 3px solid #0a192f;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.counter label {
  margin-bottom: 50px;
  color: var(--item-color);
  font-size: calc(70 / 16 * 1rem);
}
.counter__btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:768px) {
  .counter__btn{
    flex-direction: column;
  }
}
.counter__btn button {
  padding: 10px 30px;
  margin: 0 20px 10px 20px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.counter__btn button i {
  transition: all 0.7s ease;
}

.counter__btn #decrease__btn {
  color: var(--extra--color);
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
}

.counter__btn #reset__btn {
  color: var(--main-color);
  background: none;
  border: 2.5px solid var(--main-color);
  border-radius: 5px;
  font-size: calc(19 / 16 * 1rem);
  font-weight: 700;
}

.counter__btn #increase__btn {
  color: var(--extra--color);
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-size: calc(19 / 16 * 1rem);
  font-weight: 700;
}

.counter__btn #decrease__btn:hover,
.counter__btn #increase__btn:hover {
  background: #60f1cfd3;
}

/* //todo app */
.wrapper {
  max-width: 405px;
  background: #fff;
  margin: 3rem auto;
  border-radius: 7px;
  padding: 28px 0 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.task-input {
  position: relative;
  height: 52px;
  padding: 0 25px;
}

.task-input .pen {
  position: absolute;
  top: 50%;
  color: #999;
  font-size: 25px;
  transform: translate(17px, -50%);
}
.task-input .plus {
  position: absolute;
  top: 50%;
  color: #999;
  font-size: 40px;
  transform: translate(-35px, -50%);
  cursor: pointer;
}
.delete{
  cursor: pointer;
}

.task-input input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 18px;
  border-radius: 5px;
  padding: 0 20px 0 53px;
  border: 1px solid #999;
}

.task-input input:focus,
.task-input input.active {
  padding-left: 52px;
  border: 2px solid #f12711;
}

.task-input input::placeholder {
  color: #bfbfbf;
}

.controls,.wrapper li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controls {
  padding: 18px 25px;
  border-bottom: 1px solid #ccc;
}

.filters span {
  margin: 0 8px;
  font-size: 17px;
  color: #444444;
  cursor: pointer;
}

.filters span:first-child {
  margin-left: 0;
}

.filters span.active {
  color: #f12711;
}

.clear-btn {
  border: none;
  opacity: 0.6;
  outline: none;
  color: #fff;
  cursor: not-allowed;
  font-size: 13px;
  padding: 7px 13px;
  border-radius: 4px;
  letter-spacing: 0.3px;
  pointer-events: none;
  transition: transform 0.25s ease;
  background: linear-gradient(135deg, #f5af19 0%, #f12711 100%);
}

.clear-btn.active {
  opacity: 0.9;
  pointer-events: auto;
  cursor: pointer;
}

.clear-btn:active {
  transform: scale(0.93);
}

.task-box {
  margin-top: 20px;
  margin-right: 5px;
  padding: 0 20px 10px 25px;
}

.task-box.overflow {
  overflow-y: auto;
  max-height: 300px;
}

.task-box::-webkit-scrollbar {
  width: 5px;
}

.task-box::-webkit-scrollbar-track {
  background: #f12711;
  border-radius: 25px;
}

.task-box::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 25px;
}

.task-box .task {
  list-style: none;
  font-size: 17px;
  margin-bottom: 18px;
  padding-bottom: 16px;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
}

.task-box .task:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.task-box .task label {
  display: flex;
  align-items: flex-start;
}

.task label input {
  margin-top: 7px;
  accent-color: #f12711;
}

.task label p {
  user-select: none;
  margin-left: 12px;
  word-wrap: break-word;
}

.task label p.checked {
  text-decoration: line-through;
}


.task-box .task:last-child .task-menu {
  bottom: 0;
  transform-origin: bottom right;
}

.task-box .task:first-child .task-menu {
  bottom: -65px;
  transform-origin: top right;
}

.task-menu.show {
  transform: scale(1);
}

.task-menu li {
  height: 25px;
  font-size: 16px;
  margin-bottom: 2px;
  padding: 17px 15px;
  cursor: pointer;
  justify-content: flex-start;
}

.task-menu li:last-child {
  margin-bottom: 0;
}


@media (max-width: 400px) {
  body {
    padding: 0 10px;
  }
  .wrapper {
    padding: 20px 0;
  }
  .filters span {
    margin: 0 5px;
  }
  .task-input {
    padding: 0 20px;
  }
  .controls {
    padding: 18px 20px;
  }
  .task-box {
    margin-top: 20px;
    margin-right: 5px;
    padding: 0 15px 10px 20px;
  }
  .task label input {
    margin-top: 4px;
  }
}


/* //calculator */

.calc-body {
  background-color: #243441;
  backdrop-filter: blur (10px);
  border-radius: 20px;
  padding: 40px 30px;
  width: 330px;
  margin: auto;
}
.result-display {
  color: white;
  background-color: #13181d25;
  height: 50px;
  padding: 20px;
  text-align: right;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.calc-display h3,
.result-display h1 {
  margin: 0;
  font-weight: 300;
}
.calc-display {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #13181d25;
  color: white;
  height: 50px;
  padding: 0 15px;
  text-align: right;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.btn-wrap {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.btn {
  padding: 10px;
  height: 55px;
  width: 55px;
  font-size: medium;
  color: rgb(191, 191, 191) !important;
  background-color: #243441;
  border: none;
  outline: none;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}
.btn:hover {
  background-color: #212b33 !important;
}
.btn:active {
  background-color: #13181d !important;
  box-shadow: none;
}
.btn-orange {
  color: #ed802e !important;
}
.btn-lg {
  width: 120px;
  background-color: #ed802e !important;
  color: #243441;
}
.btn-lg:hover {
  background-color: #e3741f !important;
}


.code-screen .ytp-chrome-top{
  display: none !important;
}
.mainSaidBar{
  position: sticky;
top: 2rem;
max-height: 95vh;
overflow-y: scroll;
}
.mainSaidBar::-webkit-scrollbar{
  display: none;
}